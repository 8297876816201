import { createSlice } from '@reduxjs/toolkit'

const name = 'ui'
const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
}

const uiSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setAsideShow: (state, action) => {
      state.asideShow = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
  },
})

export const { setSidebarShow, setAsideShow, setTheme } = uiSlice.actions
export const uiActions = { ...uiSlice.actions, setSidebarShow, setAsideShow, setTheme }
export const uiReducer = uiSlice.reducer
