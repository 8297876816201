import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createQuotation, getQuotations, updateQuotation } from 'src/_helpers/api'

// create slice

const name = 'quptations'

const initialState = createInitialState()

// Define an async thunk for the login action
const addQuotationAsync = createAsyncThunk(`${name}/add-quotation`, async (quotationData) => {
  // Send a UPDATE request to the authenticate endpoint
  console.log('🚀 ~ addQuotationAsync ~ quotationData:', quotationData)
  let response
  if (quotationData.id) {
    response = await updateQuotation(quotationData)
  } else {
    response = await createQuotation(quotationData)
  }
  return response
})

const getAllQuotationsAsync = createAsyncThunk(`${name}/get-quotations`, async () => {
  const response = await getQuotations()
  console.log('🚀 ~ returncreateAsyncThunk ~ response:', response)
  return response
})

const quotationSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the loginAsync action
      .addCase(addQuotationAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addQuotationAsync.fulfilled, (state, action) => {
        state.quotation = action.payload
        console.log('🚀 ~ addQuotationAsync.fulfilled ~ action:', action)
      })
      .addCase(addQuotationAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllQuotationsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllQuotationsAsync.fulfilled, (state, action) => {
        state.quotations = action.payload
        console.log('🚀 ~ getAllQuotationsAsync.fulfilled ~ action:', action)
      })
      .addCase(getAllQuotationsAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    quotations: [],
  }
}

export const quotationActions = {
  ...quotationSlice.actions,
  getAllQuotationsAsync,
  addQuotationAsync,
}
export const quotationsReducer = quotationSlice.reducer
