import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createUser, getUsers, updateUser } from 'src/_helpers/api'

// create slice

const name = 'users'

const initialState = createInitialState()

// Define an async thunk for the login action
const addUserAsync = createAsyncThunk(`${name}/add-user`, async (userData) => {
  // Send a UPDATE request to the authenticate endpoint
  console.log('🚀 ~ addUserAsync ~ userData:', userData)
  let response
  if (userData.id) {
    response = await updateUser(userData)
  } else {
    response = await createUser(userData)
  }
  return response
})

const getAllUsersAsync = createAsyncThunk(`${name}/get-users`, async () => {
  const response = await getUsers()
  console.log('🚀 ~ returncreateAsyncThunk ~ response:', response)
  return response
})

const userSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the loginAsync action
      .addCase(addUserAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.user = action.payload
        console.log('🚀 ~ addUserAsync.fulfilled ~ action:', action)
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllUsersAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload
        console.log('🚀 ~ getAllUsersAsync.fulfilled ~ action:', action)
      })
      .addCase(getAllUsersAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    users: [],
  }
}

export const userActions = { ...userSlice.actions, getAllUsersAsync, addUserAsync }
export const usersReducer = userSlice.reducer
