import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import './scss/style.scss'
import { history } from './_helpers/history'
// import { checkTokenExpiration } from './_helpers/utils'
import ForgetPassword from './views/pages/forgetPassword/ForgetPassword'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

const App = () => {
  history.navigate = useNavigate()
  history.location = useLocation()

  useEffect(() => {
    // checkTokenExpiration() @TODO: remove this line
  }, [])
  return (
    // <HashRouter>
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/forget-password" name="Forget Password" element={<ForgetPassword />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
    // </HashRouter>
  )
}

export default App
