import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getProducts } from 'src/_helpers/api'

// create slice

const name = 'products'

const initialState = createInitialState()

const getProductsAsync = createAsyncThunk(`${name}/get-products`, async () => {
  const response = await getProducts()
  return response
})

const productSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the loginAsync action
      .addCase(getProductsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.products = action.payload.data
      })
      .addCase(getProductsAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    products: [],
  }
}

export const productActions = {
  ...productSlice.actions,
  getProductsAsync,
}
export const productsReducer = productSlice.reducer
