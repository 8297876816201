// ToastContext.js

import React, { createContext, useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react-pro'

const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [toast, addToast] = useState(0)
  const toaster = useRef()

  const createToast = (toast) => {
    console.log('🚀 ~ createToast ~ toast:', toast)
    addToast(
      <CToast>
        <CToastHeader closeButton>
          <svg
            className="rounded me-2"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
            role="img"
          >
            <rect
              width="100%"
              height="100%"
              fill={toast.type === 'success' ? '#2eb85c' : '#e55353'}
            ></rect>
          </svg>
          <div className="fw-bold me-auto">{toast.title}</div>
        </CToastHeader>
        <CToastBody>{toast.message}</CToastBody>
      </CToast>,
    )
  }

  return (
    <ToastContext.Provider value={{ createToast }}>
      {children}
      <CToaster className="p-3" placement="top-end" push={toast} ref={toaster} />
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
