import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { history } from '../_helpers'
import { authenticate, sendPassword } from 'src/_helpers/api'

// Define the name of the slice
const name = 'auth'

// Create the initial state by calling the createInitialState function
const initialState = createInitialState()

// Define an async thunk for the login action
const loginAsync = createAsyncThunk(`${name}/login`, async (useData) => {
  // Send a POST request to the authenticate endpoint
  const response = await authenticate(useData)
  if (response.user) {
    localStorage.setItem('user', JSON.stringify(response.user))
    if (useData.rememberMe) {
      localStorage.setItem('token', JSON.stringify(response.token))
    } else {
      sessionStorage.setItem('token', JSON.stringify(response.token))
    }
  }
  return response.data
})

// Define an async thunk for the login action
const sendPasswordAsync = createAsyncThunk(`${name}/generate-password`, async ({ email }) => {
  // Send a POST request to the authenticate endpoint
  const response = await sendPassword(email)

  if (response.success) {
    // forgotPassword set
  }
  return response.data
})

// Define the auth slice
const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    // Define a reducer for the logout action
    logout: (state) => {
      // Clear the user from the state and local storage, and navigate to the login page
      state.user = null
      state.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      history.navigate('/login')
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the loginAsync action
      .addCase(loginAsync.pending, (state) => {
        state.error = null
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.user = JSON.parse(localStorage.getItem('user'))
        state.token = state.user.rememberMe
          ? sessionStorage.getItem('token')
          : localStorage.getItem('token')
        const { from } = history.location.state || { from: { pathname: '/' } }
        history.navigate(from)
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

// Define a function to create the initial state
function createInitialState() {
  const user = JSON.parse(localStorage.getItem('user'))
  const token = JSON.parse(
    user?.rememberMe ? sessionStorage.getItem('token') : localStorage.getItem('token'),
  )
  return {
    user: user,
    token: token,
    error: null,
    forgetPasswordMessage: null,
  }
}

// Export the logout action creator
export const { logout } = authSlice.actions

// Export all action creators and the async thunk as authActions
export const authActions = { ...authSlice.actions, loginAsync, sendPasswordAsync }

// Export the reducer
export const authReducer = authSlice.reducer
