import { configureStore } from '@reduxjs/toolkit'

import { authReducer } from './auth.slice'
import { usersReducer } from './users.slice'
import { uiReducer } from './ui.slice'
import { productsReducer } from './products.slice'
import { templatesReducer } from './templates.slice'
import { projectsReducer } from './projects.slice'
import { stepsReducer } from './steps.slice'
import { quotationsReducer } from './quotations.slice'

export * from './auth.slice'
export * from './users.slice'
export * from './ui.slice'
export * from './templates.slice'
export * from './products.slice'
export * from './projects.slice'
export * from './steps.slice'
export * from './quotations.slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    ui: uiReducer,
    templates: templatesReducer,
    projects: projectsReducer,
    products: productsReducer,
    steps: stepsReducer,
    quotations: quotationsReducer,
  },
})
