import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createProject, getProjects, updateProject } from 'src/_helpers/api'

// create slice

const name = 'projects'

const initialState = createInitialState()

// Define an async thunk for the login action
const addProjectAsync = createAsyncThunk(`${name}/add-project`, async (projectData) => {
  // Send a UPDATE request to the authenticate endpoint
  console.log('🚀 ~ addProjectAsync ~ projectData:', projectData)
  let response
  if (projectData.id) {
    response = await updateProject(projectData)
  } else {
    response = await createProject(projectData)
  }
  return response
})

const getAllProjectsAsync = createAsyncThunk(`${name}/get-projects`, async () => {
  const response = await getProjects()
  return response
})

const projectSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the loginAsync action
      .addCase(addProjectAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addProjectAsync.fulfilled, (state, action) => {
        state.project = action.payload
      })
      .addCase(addProjectAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllProjectsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllProjectsAsync.fulfilled, (state, action) => {
        state.projects = action.payload.data
      })
      .addCase(getAllProjectsAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    projects: [],
  }
}

export const projectActions = {
  ...projectSlice.actions,
  getAllProjectsAsync,
  addProjectAsync,
}
export const projectsReducer = projectSlice.reducer
