import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
  CAlert,
  CAlertHeading,
} from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../../_store'

const ForgetPassword = () => {
  // Get the dispatch function from Redux
  const dispatch = useDispatch()
  // Define state variables for the username and password
  const forgetPasswordMessage = useSelector((state) => state.auth.forgetPasswordMessage)

  const [validated, setValidated] = useState(false)
  const [email, setEmail] = useState('')

  // Define a function to handle the form submission
  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      // Prevent the default form submission behavior
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else {
      setValidated(true)
      // Dispatch the sendPasswordAsync action with the username
      // This will trigger the login process as defined in the auth slice
      return dispatch(authActions.sendPasswordAsync({ email }))
    }
  }

  const CardVars = {
    '--cui-card-bg': '#fff0',
  }

  const HeadingVars = {
    '--cui-heading-color': '#fff',
  }

  return (
    <div className="bg-login min-vh-100 d-flex flex-row align-items-center text-white">
      <CContainer>
        {forgetPasswordMessage && (
          <CRow className="justify-content-center">
            <CCol sm={6}>
              <CCard className="p-4" style={CardVars}>
                <CAlert color="success">
                  <CAlertHeading component="h4">Email sent!</CAlertHeading>
                  <p>Account recovery email sent to ffefe@defe.fr.</p>
                  <hr />
                  <p className="mb-0">
                    If you don’t see this email in your inbox within 15 minutes, look for it in your
                    junk mail folder. If you find it there, please mark it as “Not Junk”.
                  </p>
                </CAlert>
              </CCard>
            </CCol>
          </CRow>
        )}
        <CRow className="justify-content-center">
          <CCol sm={9}>
            <CCardGroup>
              <CCard className="p-4" style={CardVars}>
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                    <h1 style={HeadingVars}>Password Forgotten</h1>
                    <p className="text-medium-emphasis text-white">
                      Forgot your account’s password? Enter your email address and we’ll send you a
                      recovery link.
                    </p>
                    <CFormInput
                      placeholder="Email"
                      floatingLabel="Email"
                      className="mb-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      type="email"
                      required
                    />
                    <CRow>
                      <CCol xs={12}>
                        <div className="d-grid gap-2">
                          <CButton color="info" className="px-4" type="submit">
                            Send recovery email
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgetPassword
