import { createSlice } from '@reduxjs/toolkit'

const name = 'steps'
const initialState = {
  quotationsFormStep: 1,
  templatesFormStep: 1,
  usersFormStep: 1,
}

const stepsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setQuotationsFormStep: (state, action) => {
      state.quotationsFormStep = action.payload
    },
    setTemplatesFormStep: (state, action) => {
      state.templatesFormStep = action.payload
    },
    setUsersFormStep: (state, action) => {
      state.usersFormStep = action.payload
    },
  },
})

export const { setQuotationsFormStep, setTemplatesFormStep, setUsersFormStep } = stepsSlice.actions
export const stepsReducer = stepsSlice.reducer
